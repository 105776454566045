import { boldPipe } from './bold.pipe';
import { CurrentPipe } from './current.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataFormatPipe } from './data-format.pipe';
import { PlacaPipe } from './placa.pipe';
import { PersonPipe } from './person.pipe';
import { currentBrPipe } from './current-br.pipe';
import { ObjectToArray } from'./objctToArray.pipe';
import { TipoVinculoPipe } from './tipo-vinculo.pipe';
import { SafePipe } from './safe.pipe';
import { TimestampPipe } from './timestamp.pipe';
import { TelefonePipe } from './telefone.pipe';

@NgModule({
  declarations: [
    DataFormatPipe,
    PlacaPipe,
    PersonPipe,
    currentBrPipe,
    CurrentPipe,
    ObjectToArray,
    TipoVinculoPipe,
    SafePipe,
    TimestampPipe,
    TelefonePipe,
    TipoVinculoPipe,
    boldPipe
  ],
  exports:[
    DataFormatPipe,
    PlacaPipe,
    PersonPipe,
    currentBrPipe,
    CurrentPipe,
    ObjectToArray,
    TipoVinculoPipe,
    SafePipe,
    TimestampPipe,
    TelefonePipe,
    TipoVinculoPipe,
    boldPipe

  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
