import { ModalDiagramPage } from './modal-diagram.page';
import { SharedModule } from './../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { abrirProjetoModule } from 'src/app/components/abrir-projeto/abrir-projeto.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    NgxDatatableModule,
    abrirProjetoModule,

  ],
  declarations: [ModalDiagramPage],
  entryComponents:[ModalDiagramPage],
  exports: [ ModalDiagramPage ]


})

export class ModalDiagramPageModule {}
