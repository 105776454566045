import { ModalDiagramPage } from './page/diagram/modal-diagram/modal-diagram.page';
import { ModalDiagramPageModule } from './page/diagram/modal-diagram/modal-diagram.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { BrMaskerModule } from 'br-mask';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuardService } from './core/auth-guard.service';
import { ServicesProvider } from './service/services';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule, OAuthStorage} from "angular-oauth2-oidc";
import { IonicStorageModule } from '@ionic/storage';
import { AppStorageProvider } from './service/app-storage-provider';
import { registerLocaleData } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { OrgchartModule } from '@dabeng/ng-orgchart';
import { DiagramPage } from './page/diagram/diagram.page';
import { OAuthHttpInterceptor } from './core/oauth-http.interceptor';
import localePt from '@angular/common/locales/pt'; registerLocaleData(localePt);
import { PipesModule } from './pipe/pipes.module';
import { BnNgIdleService } from 'bn-ng-idle';
import { HTTP } from '@ionic-native/http/ngx'
import { FooterModule } from './components/footer/footer.module';


export function storageFactory() : OAuthStorage {
    return localStorage
}
export function windowFactory() {
    return window;
}
export function documentFactory() {
    return document;
}


@NgModule({
    declarations: [AppComponent, DiagramPage ],
    exports: [],
    entryComponents: [ModalDiagramPage],
    imports: [
        BrowserModule,
        BrMaskerModule,
        FormsModule,
        // ReactiveFormsModule,
        IonicStorageModule.forRoot(),
        HttpClientModule,
        // AngularEditorModule,
        OAuthModule.forRoot(/*{
            resourceServer: {
                allowedUrls: ['http://www.angular.at/api'],
                sendAccessToken: true
            }
        }*/),
        IonicModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        PipesModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
            //disableTimeOut: "extendedTimeOut",
            extendedTimeOut: 3000,
            timeOut: 5000,
            progressBar	: true,
            enableHtml: true,
            onActivateTick: false,
        }),
        // TooltipsModule.forRoot(),
        OrgchartModule,
        ModalDiagramPageModule,
        ReactiveFormsModule,
        FooterModule

    ],
    providers: [
        StatusBar,
        HTTP,
        BnNgIdleService,
        SplashScreen,
        AuthGuardService,
        ServicesProvider,
        AppStorageProvider, 
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: OAuthStorage, useFactory: storageFactory },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OAuthHttpInterceptor,
            multi: true,
        },
        { provide: 'window', useFactory: windowFactory },
        { provide: 'document', useFactory: documentFactory }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
