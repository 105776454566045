import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dataFormat'
})
export class DataFormatPipe implements PipeTransform {

  transform(value: any): any {

    if((typeof value == 'undefined') || (value == null) ||  (value.length <= 0)) {
      return value;
    }/*else if(value.length == 10){
      //value = value.replace(/\//g, '');
    }*/
    return moment(value).format("DD/MM/YYYY");
    /*let year = value.substring(0, 4);
    let month = value.substring(4, 6);
    let day = value.substring(6, 8);

    return day + '/' + month + '/' + year;*/
  }

}
