import {Storage} from '@ionic/storage';
import {Injectable} from '@angular/core';

export const STORAGE_TEMP_KEY = "TEMP_KEY_DATA";
export const STORAGE_SEARCH_KEY = "SEARCH_KEY_DATA";
export const STORAGE_USER_KEY = "SEARCH_USER_DATA";
export const STORAGE_AUTORIZACAO_KEY = "AUTORIZACAO_KEY_DATA";

import { STORAGE_TYPE } from '../shared/model/local-storage-types.enum';
import { Subject } from 'rxjs';

@Injectable()
export class AppStorageProvider {
    
    globalPermission: boolean = false;
    globalObject: any = false;
    
    constructor(public storage: Storage) {
    }

    changes$ = new Subject<{
        type: 'set' | 'get' | 'remove';
        key: STORAGE_TYPE;
        value?: any;
    }>();


    get isLocalStorageSupported(): boolean {
        return !!this.storage;
    }

    set<R>(key: STORAGE_TYPE, value: R): boolean {
        if (this.isLocalStorageSupported) {
            sessionStorage.setItem(key, JSON.stringify(value));
            this.changes$.next({
                type: 'set',
                key,
                value
            });
            return true;
        }
        return false;
    }

    get<R>(key: STORAGE_TYPE): R {
        if (this.isLocalStorageSupported) {
            return JSON.parse(sessionStorage.getItem(key));
        }
        return null;
    }

    getAndObserve<R>(tipo: STORAGE_TYPE, callback: (value: R) => void): R {
        this.changes$.subscribe((change) => {
            if (change.type === 'set' && change.key === tipo) {
                callback(change.value as R);
            }
        });
        return this.get<R>(tipo);
    }

    remove(key: STORAGE_TYPE): boolean {
        if (this.isLocalStorageSupported) {
            sessionStorage.removeItem(key);
            this.changes$.next({
                type: 'remove',
                key
            });
            return true;
        }
        return false;
    }

    clear() {
        if (this.isLocalStorageSupported) {
            sessionStorage.clear();
            return true;
        }
        return false;
    }



    //set, get remove autorização.
    setAutorizacao(dados) {
        return sessionStorage.setItem(STORAGE_AUTORIZACAO_KEY, JSON.stringify(dados));
    }

    async getAutorizacao() {
        return JSON.parse(sessionStorage.getItem(STORAGE_AUTORIZACAO_KEY));
    }

    removeAutorizacao() {
        sessionStorage.removeItem(STORAGE_AUTORIZACAO_KEY);
    }

    //set, get remove search.
    setUser(dados) {
        return sessionStorage.setItem(STORAGE_USER_KEY, JSON.stringify(dados));
    }

    async getUser() {
        return JSON.parse(sessionStorage.getItem(STORAGE_USER_KEY));
    }

    removeUser() {
        sessionStorage.removeItem(STORAGE_USER_KEY);
    }

    //set, get remove search.
    setSearch(dados) {
        return sessionStorage.setItem(STORAGE_SEARCH_KEY, JSON.stringify(dados));
    }

    async getSearch() {
        return JSON.parse(sessionStorage.getItem(STORAGE_SEARCH_KEY));
    }

    removeSearch() {
        sessionStorage.removeItem(STORAGE_SEARCH_KEY);
    }

    //set, get remove user.
    setTemp(dados) {
        return sessionStorage.setItem(STORAGE_TEMP_KEY, JSON.stringify(dados));
    }

    async getTemp() {
        return JSON.parse(sessionStorage.getItem(STORAGE_TEMP_KEY));
    }

    removeTemp() {
        sessionStorage.removeItem(STORAGE_TEMP_KEY);
    }
  

    clearStorage() {
        this.removeTemp();
        this.removeSearch();
        this.removeUser();
        this.removeAutorizacao();
        sessionStorage.removeItem("DATA_EMPRESA_DETALHES");
        sessionStorage.removeItem("DATA_DETALHE_RELACOES_INATIVAS");
        sessionStorage.removeItem("DATA_RELACOES_INATIVAS");
        sessionStorage.removeItem("DATA_SOCIOS_INATIVOS");
        sessionStorage.removeItem("DATA_DIAGRAMA");


    }

}