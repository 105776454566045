import { map } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { authConfig } from '../auth.config';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { head } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class AutenticacaoService {
  loggedInEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private oauthService: OAuthService, private router: Router, private modal: ModalController) {
    this.configureWithNewConfigApi();
  }

  public configureWithNewConfigApi() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setupAutomaticSilentRefresh();
  }

  public start() {
    this.oauthService.loadDiscoveryDocument(environment.ssc_discovery)
      .then(doc => {
       // console.log(doc)
        this.oauthService.tryLogin({
            onTokenReceived: (context) => this.loggedInEmitter.emit(true)
          })
          .catch(err => {
            console.log(err);
          })
          .then(rs => {
            console.log(rs);
          });
      });
  }

  public conectar() {
    this.oauthService.loadDiscoveryDocument(environment.ssc_discovery)
      .then(doc => {
        //console.log(doc)
        this.oauthService.tryLogin({
            onTokenReceived: (context) => this.loggedInEmitter.emit(true)
        })
          .catch(err => {
            console.log(err)
          })
          .then(() => {
            if (this.oauthService.hasValidAccessToken()) {
              this.router.initialNavigation();
            }else{
              this.oauthService.initImplicitFlow();
              // this.oauthService.initImplicitFlowInPopup({height: 500, width: 300}).then(rs=>{
              //   console.log(rs);
              // })
              
              // .finally(()=>{ console.log('finalizado')})
              // .then(rs=>{
              //   console.log(rs);
              //   this.router.initialNavigation();
              // })
              // .catch(er=>{console.log(er)});
            }
          });
      });
  }

  async login(){
    
    if( !(this.oauthService.hasValidIdToken() || this.oauthService.hasValidAccessToken()) ) {
      this.oauthService.initImplicitFlow();
    }else{
      this.router.navigate(["/home"]);
    }
  }

  public logout () {
   // this.oauthService.logOut();
    this.loggedInEmitter.emit(false);
    console.log('logout')
  }

  public hasRole (): any {
    try {
      const unidadePapeis = this.oauthService.getIdentityClaims()['jsonUnidadePapeis'];
      return JSON.parse(unidadePapeis).unidade.papeis; //.filter(papel => roles.includes(papel.codigo));
    } catch (err) {
      console.error('Erro ao recuperar permissões', err.message);
      return false;
    }

  }
}
