import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'current'
})
export class CurrentPipe implements PipeTransform {

    transform(value: any): any {
      if (value === '' || value == null || value === undefined) {
        return value;
      }
      return Number(value).toLocaleString('pt-BR', {minimumFractionDigits:2})
      //return value.replace(/,/g, ".").setCharAt(value,value.length-3,',');
    }  

}

