import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { OverlayService } from 'src/app/service/overlay.service';
import { ServicesProvider } from 'src/app/service/services';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit{
  @Input() lista: any = [];
  @Input() label: any = {titulo: "Pesquisar", placeholder: "Pesquisar"}; // Campo na label do componente
  @Input() disabled: any = false;
  @Input() toDisabled: any = false;
  // @Input('update') change: EventEmitter<any> = new EventEmitter<any>();
  @Output() retorno = new EventEmitter<any>();

  isItemAvailable = false;
  searchIcon = 'search';
  data = {};
  items = [];
  constructor(
    private service: ServicesProvider,
    private toastr: ToastrService,
    private overlayService: OverlayService,
  ) {}

  async ngOnInit() {
    // console.log('init');
  }

  @Input() set value(val: any) {
    if(val)
      this.seleciona(val);
  }

  // ngOnChanges(changes: SimpleChanges) {
    
  //   const currentItem: SimpleChange = changes.value;
  //   if(currentItem){
  //     console.log(currentItem); 
  //     // console.log('prev value: ', currentItem.previousValue);
  //     // console.log('got item: ', currentItem.currentValue);
  //     // if(currentItem.currentValue){
  //     //   console.log(changes.item.currentValue);
  //     // }
  //   }
  // }


  initializeItems(){
    this.items = this.lista;
    this.searchIcon = "search";
    this.limpaItem();
  }

  

  getItems(ev: any) {
    
    // // Reset items back to all of the items
    this.initializeItems();

    // set val to the value of the searchbar
    const val = ev.target.value;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
        this.isItemAvailable = true;
        this.items = this.items.filter((item) => {
            return (item.descricao.toLowerCase().indexOf(val.toLowerCase()) > -1);
        })
    } else {
        this.isItemAvailable = false;
    }
  }

  seleciona(item){
    console.log(item);
    this.searchIcon = "checkmark";
    this.isItemAvailable = false;
    this.data = item;
    this.retorno.emit(item)
  }

  limpaItem(){
    this.retorno.emit({id:''})
  }

}
