export enum STORAGE_TYPE {

    STORAGE_TEMP_KEY = "TEMP_KEY_DATA",
    STORAGE_SEARCH_KEY = "SEARCH_KEY_DATA",
    STORAGE_USER_KEY = "SEARCH_USER_DATA",
    STORAGE_AUTORIZACAO_KEY = "AUTORIZACAO_KEY_DATA",
    STORAGE_SOCIOS= "DATA_SOCIOS",
    STORAGE_PESSOAS= "DATA_PESSOAS",
    STORAGE_IMOVEIS = "DATA_IMOVEIS",
    STORAGE_RELACIONAMENTOS = "DATA_RELACIONAMENTOS",
    STORAGE_VEICULOS = "DATA_VEICULOS",
    STORAGE_VINCULOS = "DATA_VINCULOS",
    STORAGE_EMPRESAS = "DATA_EMPRESAS",
    STORAGE_EMPRESA_DETALHES = "DATA_EMPRESA_DETALHES",
    STORAGE_PROPRIEDADES = "DATA_PROPRIEDADES",
    STORAGE_VINCULOS_INTERNO = "DATA_VINCULOS_INTERNO",
    STORAGE_VINCULOS_DETRAN = "DATA_VINCULOS_DETRAN",
    STORAGE_VINCULOS_SIDAGRO = "DATA_VINCULOS_SIDAGRO",
    STORAGE_VINCULOS_JUCEMG = "DATA_VINCULOS_JUCEMG",
    STORAGE_PAPEL = "DATA_PAPEL",
    STORAGE_PAPEL_USUARIO = "DATA_PAPEL_USUARIO",
    STORAGE_AUTUADOS = "DATA_AUTUADOS",
    STORAGE_FIADORES = "DATA_FIADORES",
    STORAGE_COOBRIGADO = "DATA_COOBRIGADO",
    STORAGE_INTEGRACOES = "DATA_INTEGRACOES",
    STORAGE_VERSOES = "DATA_VERSOES",
    STORAGE_PARAMETRIZACAO = "DATA_PARAMETRIZACAO",
    STORAGE_RELACAO_EMPRESARIAL = "DATA_RELACAO_EMPRESARIAL",
    STORAGE_LISTA_TERMO = "DATA_LISTA_TERMO",
    STORAGE_VERSAO = "DATA_VERSAO",
    STORAGE_RELACOES_INATIVAS = "DATA_RELACOES_INATIVAS",
    STORAGE_RELACOES_ATIVAS = "DATA_RELACOES_ATIVAS",
    STORAGE_DETALHE_RELACOES_INATIVAS = "DATA_DETALHE_RELACOES_INATIVAS",
    STORAGE_SOCIOS_INATIVO = "DATA_SOCIOS_INATIVOS",
    STORAGE_CODIGO_VERIFICACAO = "DATA_CODIGO_VERIFICACAO",
    STORAGE_CODIGO_CRC = "DATA_CODIGO_CRC",
    STORAGE_CONDICAO_SOCIOS = "DATA_CONDICAO_SOCIOS",
    STORAGE_PARAMETROS_INTEGRACAO = "DATA_PARAMETROS_INTEGRACAO",
    STORAGE_DIAGRAMA = "DATA_DIAGRAMA",
    STORAGE_TERMO_ACEITE = "DATA_TERMO_ACEITE",
    STORAGE_LISTA_AVISOS = "DATA_LISTA_AVISOS",

}