import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AbrirProjetoComponent } from './abrir-projeto.component';
import { PipesModule } from 'src/app/pipe/pipes.module';


@NgModule({
  declarations: [
    AbrirProjetoComponent
  ],
  exports: [
    AbrirProjetoComponent
  ],
  imports: [
    IonicModule,
    PipesModule,
  ]
})
export class abrirProjetoModule {}