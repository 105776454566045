import { IonicModule } from '@ionic/angular';
import { FooterComponent } from './footer.component';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipe/pipes.module';



@NgModule({
  declarations: [FooterComponent],
  exports:[FooterComponent],
  imports: [ IonicModule,PipesModule ],
})
export class FooterModule { }
