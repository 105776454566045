import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.ssc_issuer,

  redirectUri: environment.ssc_redirectUri,

  silentRefreshRedirectUri: environment.ssc_redirectUri,

  clientId: environment.ssc_clientId,

  dummyClientSecret: "FYriReqY17bpSiZPH96A0XAV",

  //responseType: 'code',

   scope: environment.ssc_scope,

  /*    openUri: ((uri: string) => {

      let url = new URL(uri);
      url.searchParams.delete("nonce");
      url.searchParams.delete("code_challenge");
      url.searchParams.delete("code_challenge_method");
      url.searchParams.delete("state");
      url.searchParams.append("logout_uri", environment.ssc_redirectUri);

      console.log(uri);
      console.log(url.toString());
      alert("parou");
      window.location.href = decodeURIComponent(url.toString());

    })
   */
 

 //responseType: environment.ssc_responseType,

  //logoutUrl: environment.ssc_logoutUri,
  

  //comentados
  // silentRefreshIFrameName: 'sr-iframe',
  // silentRefreshTimeout: 1000,
  // silentRefreshShowIFrame: true,
  // clearHashAfterLogin: true,
  // oidc: false,
  // nonceStateSeparator: '',
  // requireHttps: false,

  // postLogoutRedirectUri: 'http://localhost:8100/home'

  // showDebugInformation: true,
  // sessionChecksEnabled: false,
  // disableAtHashCheck: true,

};
