import { AppStorageProvider } from './service/app-storage-provider';
import { Component } from '@angular/core';
import { Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { Storage } from "@ionic/storage";
 import { Router } from "@angular/router";
 import { OAuthService } from "angular-oauth2-oidc";
import { AutenticacaoService } from './core/autenticacao.service';
import { BnNgIdleService } from 'bn-ng-idle';

// import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  title = 'session-expire';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
     private oauthService: OAuthService,
     private router: Router,
     public storage: AppStorageProvider,
    private auth: AutenticacaoService,
    private idleService: BnNgIdleService,

  ){
    this.initializeApp();
    this.auth.conectar(); //Era start
    // this.oauthService.loadDiscoveryDocument(environment.ssc_discovery).then(() => {
    //   // This method just tries to parse the token(s) within the url when
    //   // the auth-server redirects the user back to the web-app
    //   // It dosn't send the user the the login page
    //   this.oauthService.tryLogin({
    //       onTokenReceived: () => {
    //           this.router.navigate(["/"])
    //       }
    //   })
    // });
  }

  ngOnInit() {
    this.idleService.startWatching(1800).subscribe((isUserInactive) => {

      if (isUserInactive) {
        console.log('Sessão expirada - tempo ocioso');
        const currentRoute = this.router.url;
        this.storage.clearStorage();
       // this.auth.logout();
        this.oauthService.logOut();
        this.idleService.resetTimer();
      }

    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

}
