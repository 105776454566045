import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'placa'
})
export class PlacaPipe implements PipeTransform {
	transform(value: any): any {
		if (value === '' || value == null || value === undefined)
			return value;

		return value.replace(/(.+)(.{4})/, '<b>$1</b> $2');
	}
}