import { filter } from 'rxjs/operators';
import { AdicionarCarteiraPage } from './../historico-pesquisa/adicionar-carteira/adicionar-carteira.page';
import {  Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { AppStorageProvider } from 'src/app/service/app-storage-provider';
import * as _ from 'lodash';
import { NavController,ModalController, NavParams, AlertController } from '@ionic/angular';
import { ServicesProvider } from 'src/app/service/services';
import { ToastrService } from 'ngx-toastr';
import { OverlayService } from 'src/app/service/overlay.service';
import { PersonPipe} from '../../pipe/person.pipe'
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { AbrirProjetoComponent } from 'src/app/components/abrir-projeto/abrir-projeto.component';
import { ModalDiagramPage } from './modal-diagram/modal-diagram.page';
import { element } from 'protractor';
import { define } from 'cordova';
import { FormGroup, FormBuilder } from '@angular/forms';
import html2canvas from 'node_modules/html2canvas';
import { STORAGE_TYPE } from 'src/app/shared/model/local-storage-types.enum';

//https://github.com/dabeng/ng-orgchart

@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.page.html',
  styleUrls: ['./diagram.page.scss'],
  providers: [PersonPipe]
})
export class DiagramPage implements OnInit {

  @ViewChild('screen', {static: false}) screen: ElementRef;
  // @ViewChild('canvas', {static: false}) canvas: ElementRef;
  // @ViewChild('downloadLink', {static: false}) downloadLink: ElementRef;
  
  start: boolean = false;
  toggleSiblingsResp:boolean = false;
  groupScale: number = 1;
  public itens: any = {};
  public relacionamentoDetran: any = [];
  public relacionamentoArgos: any = [];
  public relacionamentoJucemg: any = [];
  public relacionamentoSerpro: any = [];

  public relacionamentoSidagro: any = [];
  public mostrarLegenda: any = false;
  public cloneItem: any = [];
  public childrenNivelUm: any = [];
  public childrenNivelDois: any = [];
  public childrenNivelTres: any = [];
  public contadorDetran: any =[];
  public erro: any = {}; // Estado do serviço 0: sem erro / 1: com erro / 2: sem estado(carregando)
  public item: any = [];
  public item_aux: any = [];
  public exibeFooter: boolean = true; 
  public detran: any = []
  public user:any = '';
  public date: any = moment().format('DD/MM/YYYY');
  letter = [ 'MANUAL', 'JUCEMG', 'SIDAGRO', 'DETRAN', 'SERPRO'];
  public myForm: FormGroup;
  public titlePrint: string ='teste'

  constructor( 
    public storage: AppStorageProvider, 
    private alertController: AlertController,
    private services: ServicesProvider,
    private toastr: ToastrService,
    private overlayService: OverlayService,
    public modalController: ModalController,
    private person: PersonPipe,
    private router: Router, 	
    private route: ActivatedRoute, 
    public formBuilder: FormBuilder,

    ){
      this.myForm = this.formBuilder.group({    
        check_jucemg:false,
        check_detran:false,
        check_argos:false,
        check_sidagro:false	   , 
        check_serpro:false	    

        });	 
  }

  
  ngOnInit(): void { 
    this.exibeFooter = true
    this.vinculosDetran();  
    this.vinculos();
    this.storage.getUser().then(rs => {
      this.user = rs; // informações do u
      document.title ="Argos" ;

   })
  }

  vinculos(){
		this.services.request('/pessoa/vinculo', 'get')
			.then(result => {
				//this.items.relacionamentoJucemg = (result.response) ? result.response : [];
				//	this.checkItens(true,this.items.relacionamentoJucemg, true)			
                                    
				this.itens.selected = (result.response) ? result.response : []; // retorna todos os vinculos ARGOS e JUCEMG

				if (this.itens.selected) {
					let retorno = this.itens.selected.filter(function (element) {
						if (element.sistema == "Jucemg") return true
					}).map(function (element) {
						return element
					})
					//Filtro uisuários Argos
					let retornoArgos = this.itens.selected.filter(function(element){
						if(element.sistema=="Usuário Argos") return true	
					}).map(function (element) {
						return element
					})
					let retornoSidagro = this.itens.selected.filter(function(element){
						if(element.sistema=="SIDAGRO") return true	
					}).map(function (element) {
						return element
					})

          let retornoSerpro = this.itens.selected.filter(function(element){
						if(element.sistema=="SERPRO") return true	
					}).map(function (element) {
						return element
					})
					this.relacionamentoArgos = (retornoArgos.length) ? retornoArgos:false;
          this.relacionamentoJucemg = (retorno.length) ? retorno : false;
          this.relacionamentoSidagro = (retornoSidagro.length) ? retornoSidagro : false;
          this.relacionamentoSerpro =  (retornoSerpro.length) ? retornoSerpro : false;

 
				}else {
					this.relacionamentoJucemg = false;
					this.relacionamentoArgos = false;
					this.relacionamentoSidagro = false;
          this.relacionamentoSerpro = false
				  }

			})
			.catch(err =>
				this.toastr.error(err.message)
			)
      .finally(() => { });    

     
  }

  vinculosDetran() {
    const loading =  this.overlayService.loading();
		this.services.request('/detran/relacionamento')
			.then(rs => {
        this.itens.contadorDetran = rs.response
				if (rs.response.length) {

					this.itens.relacionamentoDetran = rs.response.filter(function(element){
          if(element.validado_pelo_procurador === "1") return true	
          }).map(function (element) {
						return element})
				} else {
					this.erro.relacionamentoDetran = _.merge(this.erro.relacionamentoDetran, { 'detran': 1 });
				}
			})
			.catch(err => {
        console.log('erro', err)
				this.toastr.error('Erro ao conectar em <b>VÍNCULOS DETRAN</b>')
				this.erro.relacionamentoDetran = _.merge(this.erro.relacionamentoDetran, { 'detran': true });
			})
      .finally(async () => {(await loading).dismiss() ,this.init() });  
  } 

   init(){      
    const loading =  this.overlayService.loading();
    this.services.request('/relatorio/vinculo', 'get').then((rs)=>{ 
      // rs.response.pesquisado.children[0].sistemaJucemg = 'Jucemg'
      // rs.response.pesquisado.children.push({
      //   children: [],
      //   nome: 'JAQUELINE ALEXANDRE DE OLIVEIRA',
      //   cpf_cnpj : "13028136686",
      //   sistema: 'Detran',
      //   tipo_vinculo:"FILHO"
      // })

      // rs.response.pesquisado.children.push({
      //   children: [],
      //   nome: 'JAQUELINE ALEXANDRE DE OLIVEIRA',
      //   cpf_cnpj : "13028136686",
      //   sistema: 'SIDAGRO',
      //   tipo_vinculo:"FILHO"
      // })

      // rs.response.pesquisado.children[0].children.push({
      //   children: [],
      //   nome: 'JAQUELINE ALEXANDRE DE OLIVEIRA',
      //   cpf_cnpj : "13028136686",
      //   sistema: 'SIDAGRO',
      //   tipo_vinculo:"SÓCIO"
      // })


      // rs.response.pesquisado.children[0].children.push({
      //   children: [],
      //   nome: 'OLIVIA NEWTON JOHN',
      //   cpf_cnpj : "79117627672",
      //   sistema: 'Detran',
      //   tipo_vinculo:"SÓCIO"
      // })

      // rs.response.pesquisado.children[0].children.push({
      //   children: [],
      //   nome: 'DIONISIO MACHADO',
      //   cpf_cnpj : "21361075856",
      //   sistema: 'Detran',
      //   tipo_vinculo:"SÓCIO"
      // })
      // rs.response.pesquisado.children[0].children[1].children.push({
      //   children: [],
      //   nome: "JULIANE SILVA DAMASCENO",
      //   cpf_cnpj : "05113623660",
      //   sistema: 'Detran',
      //   tipo_vinculo:"FILHO"
      // })   
  
      if(rs.response){
        this.itens.cloneItem = rs.response.pesquisado;  
        this.itens.cloneItem.sistema = "Origem";
        this.itens.cloneItem.tipo_vinculo = "Origem";
      } 
      if(this.itens.relacionamentoDetran && this.itens.relacionamentoDetran.length>0){
        this.itens.relacionamentoDetran.forEach(element => {
        element.tipo_vinculo = element.tipo_vinculo_nome
        element.sistema ='Detran'
        element.cpf_cnpj = element.cpf_vinculo
        this.itens.cloneItem.children.push(element)
        });
       
      }       
      else{
          this.start = true;
        }
        if (this.itens.cloneItem.children) {
          this.itens.cloneItem.children = this.removeDuplicateObjects(this.itens.cloneItem.children)
          this.itens.cloneItem.children =  this.verificacaoSistema(this.itens.cloneItem.children)
          this.itens.cloneItem.children.forEach(element => {
            if (element.children) {
              
              element.children = this.verificacaoSistema(element.children)
              
              if (element.children.length > 0) { 
                element.children = this.removeDuplicateObjects(element.children);
                element.children.forEach(el => {
                  
                  if(el.children){
                  el.children = this.verificacaoSistema(el.children)
                    el.children = this.removeDuplicateObjects(el.children);
                    this.childrenNivelDois.push(el);
                    el.children.forEach(el2 =>{
                     
                      if(el2.children){
                      el2.children = this.verificacaoSistema(el2.children)
                        if(el2.children === 0){
                          delete el2.children;
                        }
                      }
                      this.childrenNivelTres.push(el2);

                    })
                  }
                  if (el.children.length === 0) {
                    delete el.children
                  }
                })
              } else {
                delete element.children
              }
              this.childrenNivelUm.push(element);


            }
          })
        }
        this.itens.item = this.itens.cloneItem;
        this.itens.item_aux = this.itens.cloneItem;
        this.storage.set( STORAGE_TYPE.STORAGE_DIAGRAMA, this.itens.item)
        
      // this.parseSetItem(rs.response, this.item);
    }).catch(err => {
        console.log(err)
        this.toastr.error('Erro ao conectar.');
    }).finally(async ()=>{ (await loading).dismiss(); })


  }
  verificacaoObjetoIguais(nodedata) {
    let cpf_cnpj = nodedata.cpf_cnpj;

    const todasArrays = [...this.childrenNivelUm, ...this.childrenNivelDois, ...this.childrenNivelTres];

    const cpfsCnpjsIguais = todasArrays.filter(item => item.cpf_cnpj === cpf_cnpj);
    const maisDeUm = cpfsCnpjsIguais.length > 1;
    return maisDeUm;
}

  filtro(){    
    let form = this.myForm.value;

    this.itens.item = this.storage.get(STORAGE_TYPE.STORAGE_DIAGRAMA);

    this.itens.item.children = this.itens.item.children.filter(element=>{
  
    
     if(form.check_argos === false && form.check_detran === false && form.check_jucemg === false && form.check_sidagro === false  && form.check_serpro === false){  
        return(element);
      }

if(form.check_argos === true && form.check_detran === false && form.check_jucemg === false && form.check_sidagro === false && form.check_serpro === false){
  if(element.sistema == "Usuário Argos" || element.sistemaUsuario){
    
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === true && form.check_jucemg === false && form.check_sidagro === false && form.check_serpro === false){
  if(element.sistema == "Detran" || element.sistemaDetran){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === false && form.check_jucemg === true && form.check_sidagro === false && form.check_serpro === false){
  if(element.sistema == "Jucemg" || element.sistemaJucemg){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === false && form.check_jucemg === false && form.check_sidagro === true && form.check_serpro === false){
  if(element.sistema == "SIDAGRO" || element.sistemaSidagro){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === false && form.check_jucemg === false && form.check_sidagro === false && form.check_serpro === true){
  if(element.sistema == "SERPRO" || element.sistemaSerpro){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === true && form.check_jucemg === false && form.check_sidagro === false && form.check_serpro === false){
  if(element.sistema == "Usuário Argos" || element.sistema == "Detran" || element.sistemaDetran || element.sistemaUsuario){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === false && form.check_jucemg === true && form.check_sidagro === false && form.check_serpro === false){
  if(element.sistema == "Usuário Argos" || element.sistema == "Jucemg" || element.sistemaJucemg || element.sistemaUsuario){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === false && form.check_jucemg === false && form.check_sidagro === true && form.check_serpro === false){
  if(element.sistema == "Usuário Argos" || element.sistema == "SIDAGRO" || element.sistemaSidagro || element.sistemaUsuario){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === false && form.check_jucemg === false && form.check_sidagro === false && form.check_serpro === true){
  if(element.sistema == "Usuário Argos" || element.sistema == "SERPRO" || element.sistemaSerpro || element.sistemaUsuario){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === true && form.check_jucemg === true && form.check_sidagro === false && form.check_serpro === false){
  if(element.sistema == "Detran" || element.sistema == "Jucemg" || element.sistemaJucemg || element.sistemaDetran){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === true && form.check_jucemg === false && form.check_sidagro === true && form.check_serpro === false){
  if(element.sistema == "Detran" || element.sistema == "SIDAGRO" || element.sistemaSidagro || element.sistemaDetran){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === true && form.check_jucemg === false && form.check_sidagro === false && form.check_serpro === true){
  if(element.sistema == "Detran" || element.sistema == "SERPRO" || element.sistemaSerpro || element.sistemaDetran){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === false && form.check_jucemg === true && form.check_sidagro === true && form.check_serpro === false){
  if(element.sistema == "Jucemg" || element.sistema == "SIDAGRO" || element.sistemaSidagro || element.sistemaJucemg){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === false && form.check_jucemg === true && form.check_sidagro === false && form.check_serpro === true){
  if(element.sistema == "Jucemg" || element.sistema == "SERPRO" || element.sistemaSerpro || element.sistemaJucemg){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === false && form.check_jucemg === false && form.check_sidagro === true && form.check_serpro === true){
  if(element.sistema == "SIDAGRO" || element.sistema == "SERPRO" || element.sistemaSerpro || element.sistemaSidagro){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === true && form.check_jucemg === true && form.check_sidagro === false && form.check_serpro === false){
  if(element.sistema == "Usuário Argos" || element.sistema == "Detran" || element.sistema == "Jucemg" || element.sistemaDetran || element.sistemaJucemg || element.sistemaUsuario){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === true && form.check_jucemg === false && form.check_sidagro === true && form.check_serpro === false){
  if(element.sistema == "Usuário Argos" || element.sistema == "Detran" || element.sistema == "SIDAGRO" || element.sistemaDetran || element.sistemaSidagro || element.sistemaUsuario){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === true && form.check_jucemg === false && form.check_sidagro === false && form.check_serpro === true){
  if(element.sistema == "Usuário Argos" || element.sistema == "Detran" || element.sistema == "SERPRO" || element.sistemaDetran || element.sistemaSerpro || element.sistemaUsuario){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === false && form.check_jucemg === true && form.check_sidagro === true && form.check_serpro === false){
  if(element.sistema == "Usuário Argos" || element.sistema == "Jucemg" || element.sistema == "SIDAGRO" ||element.sistemaJucemg || element.sistemaSidagro || element.sistemaUsuario){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === false && form.check_jucemg === true && form.check_sidagro === false && form.check_serpro === true){
  if(element.sistema == "Usuário Argos" || element.sistema == "Jucemg" || element.sistema == "SERPRO" || element.sistemaJucemg || element.sistemaSerpro || element.sistemaUsuario){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === false && form.check_jucemg === false && form.check_sidagro === true && form.check_serpro === true){
  if(element.sistema == "Usuário Argos" || element.sistema == "SIDAGRO" || element.sistema == "SERPRO" || element.sistemaUsuario || element.sistemaSidagro || element.sistemaSerpro){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === true && form.check_jucemg === true && form.check_sidagro === true && form.check_serpro === false){
  if(element.sistema == "Detran" || element.sistema == "Jucemg" || element.sistema == "SIDAGRO" || element.sistemaDetran || element.sistemaSidagro || element.sistemaJucemg){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === true && form.check_jucemg === true && form.check_sidagro === false && form.check_serpro === true){
  if(element.sistema == "Detran" || element.sistema == "Jucemg" || element.sistema == "SERPRO" || element.sistemaDetran  || element.sistemaJucemg  || element.sistemaSerpro){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === true && form.check_jucemg === false && form.check_sidagro === true && form.check_serpro === true){
  if(element.sistema == "Detran" || element.sistema == "SIDAGRO" || element.sistema == "SERPRO" || element.sistemaDetran || element.sistemaSidagro || element.sistemaSerpro){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === false && form.check_jucemg === true && form.check_sidagro === true && form.check_serpro === true){
  if(element.sistema == "Jucemg" || element.sistema == "SIDAGRO" || element.sistema == "SERPRO" || element.sistemaJucemg  || element.sistemaSidagro || element.sistemaSerpro){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === true && form.check_jucemg === true && form.check_sidagro === true && form.check_serpro === false){
  if(element.sistema == "Usuário Argos" || element.sistema == "Detran" || element.sistema == "Jucemg" || element.sistema == "SIDAGRO" || element.sistemaUsuario || element.sistemaDetran || element.sistemaJucemg || element.sistemaSidagro){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === true && form.check_jucemg === true && form.check_sidagro === false && form.check_serpro === true){
  if(element.sistema == "Usuário Argos" || element.sistema == "Detran" || element.sistema == "Jucemg" || element.sistema == "SERPRO" || element.sistemaUsuario || element.sistemaDetran|| element.sistemaJucemg|| element.sistemaSerpro){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === true && form.check_jucemg === false && form.check_sidagro === true && form.check_serpro === true){
  if(element.sistema == "Usuário Argos" || element.sistema == "Detran" || element.sistema == "SIDAGRO" || element.sistema == "SERPRO" || element.sistemaUsuario || element.sistemaDetran || element.sistemaSidagro|| element.sistemaSerpro){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === false && form.check_jucemg === true && form.check_sidagro === true && form.check_serpro === true){
  if(element.sistema == "Usuário Argos" || element.sistema == "Jucemg" || element.sistema == "SIDAGRO" || element.sistema == "SERPRO" || element.sistemaUsuario || element.sistemaJucemg  || element.sistemaSidagro  || element.sistemaSerpro ){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === false && form.check_detran === true && form.check_jucemg === true && form.check_sidagro === true && form.check_serpro === true){
  if(element.sistema == "Detran" || element.sistema == "Jucemg" || element.sistema == "SIDAGRO" || element.sistema == "SERPRO" || element.sistemaDetran || element.sistemaJucemg || element.sistemaSidagro || element.sistemaSerpro ){
      element.children = [];
      return(element);
  }
}

if(form.check_argos === true && form.check_detran === true && form.check_jucemg === true && form.check_sidagro === true && form.check_serpro === true){
  if(element.sistema == "Usuário Argos" || element.sistema == "Detran" || element.sistema == "Jucemg" || element.sistema == "SIDAGRO" || element.sistema == "SERPRO" || element.sistemaUsuario || element.sistemaDetran || element.sistemaJucemg || element.sistemaSidagro || element.sistemaSerpro){
      element.children = [];
      return(element);
  }
}

      
    })   

  }

  verificacaoSistema(array) {
    const sistemaUnificado = [];
    array.forEach((objeto) => {
      const index = sistemaUnificado.findIndex((objeto2) => objeto2.cpf_cnpj === objeto.cpf_cnpj);
      if (index === -1) {
        sistemaUnificado.push(objeto);
      } else {
        if (objeto.sistema !== sistemaUnificado[index].sistema) {
         
          if (objeto.sistema === "SIDAGRO") {
            sistemaUnificado[index].sistemaSidagro = "SIDAGRO";
            
           
            if(objeto.tipo_vinculo !== sistemaUnificado[index].tipo_vinculo){
              if( sistemaUnificado[index].tipo_vinculo === 'SÓCIO'){
                sistemaUnificado[index].tipo_vinculo_novo = 'SÓCIO'   + '/' + objeto.tipo_vinculo

              }else{
                sistemaUnificado[index].tipo_vinculo_novo =  sistemaUnificado[index].tipo_vinculo + '/' + objeto.tipo_vinculo
              }
              }
          } 
          
          if (objeto.sistema === "Jucemg") {
            sistemaUnificado[index].sistemaJucemg = "Jucemg";
           
            if(objeto.tipo_vinculo !== sistemaUnificado[index].tipo_vinculo){
              if( sistemaUnificado[index].tipo_vinculo === 'SÓCIO'){
                sistemaUnificado[index].tipo_vinculo_novo = 'SÓCIO'   + '/' + objeto.tipo_vinculo

              }else{
                sistemaUnificado[index].tipo_vinculo_novo =  sistemaUnificado[index].tipo_vinculo + '/' + objeto.tipo_vinculo
              }
              }
          } 
          if (objeto.sistema === 'Detran') {
            sistemaUnificado[index].sistemaDetran = 'Detran';
          
            if(objeto.tipo_vinculo !== sistemaUnificado[index].tipo_vinculo){
              if( sistemaUnificado[index].tipo_vinculo === 'SÓCIO'){
                sistemaUnificado[index].tipo_vinculo_novo = 'SÓCIO/RESPONSAVEL'   + '/' + objeto.tipo_vinculo

              }else{
                sistemaUnificado[index].tipo_vinculo_novo =  sistemaUnificado[index].tipo_vinculo + '/' + objeto.tipo_vinculo
              }
              }
          } 
         
          if (objeto.sistema === 'SERPRO') {
            sistemaUnificado[index].sistemaSerpro = 'Serpro';
          
            if(objeto.tipo_vinculo !== sistemaUnificado[index].tipo_vinculo){
              if( sistemaUnificado[index].tipo_vinculo === 'SÓCIO'){
                sistemaUnificado[index].tipo_vinculo_novo = 'SÓCIO/RESPONSAVEL'   + '/' + objeto.tipo_vinculo

              }else{
                sistemaUnificado[index].tipo_vinculo_novo =  sistemaUnificado[index].tipo_vinculo + '/' + objeto.tipo_vinculo
              }
              }
          } 
          if (objeto.sistema === 'Usuário Argos') {
            sistemaUnificado[index].sistemaUsuario = 'Usuário Argos';
            if(objeto.tipo_vinculo !== sistemaUnificado[index].tipo_vinculo){
              if( sistemaUnificado[index].tipo_vinculo === 'SÓCIO'){
                sistemaUnificado[index].tipo_vinculo_novo = 'SÓCIO/RESPONSAVEL'   + '/' + objeto.tipo_vinculo

              }else{
                sistemaUnificado[index].tipo_vinculo_novo =  sistemaUnificado[index].tipo_vinculo + '/' + objeto.tipo_vinculo
              }
              }
          }
        }
      }
    });
  
    return sistemaUnificado;
  }
   removeDuplicateObjects(array: any[]) {
    const uniqueObjects = [];
    const seenObjects = new Set();
    for (const obj of array) {
      const objString = JSON.stringify(obj);
      if (!seenObjects.has(objString)) {
        uniqueObjects.push(obj);
        seenObjects.add(objString);
      }
    }
    return uniqueObjects;
  } 


  verificarSeContemBarra(nodeData): boolean {
     if(nodeData.tipo_vinculo_novo){
      this.mostrarLegenda = true;
      return true;
     }
   }
 
  async selectNode(nodeData:any) {
      const modal = await this.modalController.create({
        component: ModalDiagramPage,
        cssClass: 'modal-diagram',
        componentProps: { 'item':nodeData}      
        
      });
      await modal.present()
      modal.onDidDismiss().then( (rs:any) => {   
      })
    }
  
  
  print(){
    const srcElement = document.getElementById('content');
    if (srcElement) {
      html2canvas(srcElement).then((canvas) => {
        const a = document.createElement("a");
        a.href = canvas.toDataURL();
        a.download = ('Vínculos - '+ this.user.nome +'.jpg');
        a.click();
      });

    }
  }

  goHome(){
    this.overlayService.goHome()
  }

}