import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	AlertController,
	LoadingController,
	ToastController,
	ModalController
} from '@ionic/angular';
import { AlertOptions, LoadingOptions, ToastOptions, ModalOptions } from '@ionic/core';

@Injectable({
	providedIn: 'root'
})
export class OverlayService {
	constructor(
		private alertCtrl: AlertController,
		private loadingCtrl: LoadingController,
		private toastCtrl: ToastController,
		private modalController: ModalController,
		private router: Router,
		private route: ActivatedRoute,
	) {}

	async alert(options: AlertOptions): Promise<HTMLIonAlertElement> {
		const alert = await this.alertCtrl.create(options);
		await alert.present();
		return alert;
	}

	async loading(message: string = 'Aguarde...', options?: LoadingOptions): Promise<HTMLIonLoadingElement> {
		const loading = await this.loadingCtrl.create({
			message: message,
			...options
		});
		await loading.present();
		return loading;
	}
	async loadingCateira(message: string = 'Aguarde...', options?: LoadingOptions): Promise<HTMLIonLoadingElement> {
		const loading = await this.loadingCtrl.create({
			duration:60000,
			message: message,
			...options
		});
		await loading.present();
		return loading;
	}
	async loadingPta(message: string = 'Rotina manual de atualização executada...', options?: LoadingOptions): Promise<HTMLIonLoadingElement> {
		const loading = await this.loadingCtrl.create({
			duration:3000,
			message: message,
			...options
		});
		await loading.present();
		return loading;
	}
	async loadingArquivoAtosEventos(message: string = 'Aguarde o download do arquivo ...', options?: LoadingOptions): Promise<HTMLIonLoadingElement> {
		const loading = await this.loadingCtrl.create({
			duration:120000,
			message: message,
			...options
		});
		await loading.present();
		return loading;
	}

	async loadingCda(message: string = 'Aguarde...', options?: LoadingOptions): Promise<HTMLIonLoadingElement> {
		const loading = await this.loadingCtrl.create({
			duration:22000,
			message: message,
			...options
		});
		await loading.present();
		return loading;
	}

	async toast(options?: ToastOptions): Promise<HTMLIonToastElement> {
		const toast = await this.toastCtrl.create({
			position: 'bottom',
			duration: 20000,
			showCloseButton: true,
			closeButtonText: 'Ok',
			...options
		});
		await toast.present();
		return toast;
	}

	async modal(options: ModalOptions) {
		const modal = await this.modalController.create(options);
		await modal.present();
		return modal;
	}

	charformat(s: string) {
		if (s && typeof s === 'string') {
				return s.replace(/\D/g, '');
		} else {
				return s;
		}
	}

	goHome(){
		//volta para pagina home
		this.router.navigate(['login']);
	}

	geraEndereco(obj: any, row: any = {}){
        if(obj){
          let endereco: string = "";
          if(obj['tipoLogradouro']) endereco = obj.tipoLogradouro;
          if(obj.nomeLogradouro) endereco += ' ' + obj.nomeLogradouro;
          if(Number(obj.numeroLogradouro)) endereco += ', ' + obj.numeroLogradouro;
          if(obj.Complemento) endereco += ', ' + obj.complemento;
          if(obj.cep) endereco += ', ' + obj.cep;
          if(obj.bairro) endereco += ', ' + obj.bairro;
          
          if(row.municipio) {
            endereco += ', ' + row.municipio.nome;
            if(row.municipio.uf) endereco += ', ' + row.municipio.uf.nome;
          }
    
          return endereco;
        }
	}
	goBack(url: any = false) {
		if(url){
			this.router.navigate([url], {relativeTo: this.route, skipLocationChange: false});
		}else
			window.history.back();
		//window.location.replace(); //windows.location faz um refresh e limpa os campos inputs
		//	window.location.replace('/home'); // realiza um refresh na página, atualizando as tabelas de r
	}
}
