import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'person'
})
export class PersonPipe implements PipeTransform {
	transform(value: any): any {
		if (value === '' || value == null || value === undefined)
			return value;
		else if( value.length >= 14)
			return value.replace(/(\d+)(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
		else if( value.length > 12 && value.length < 14)
			return value.replace(/(\d+)(\d{8})(\d{2})(\d{2})/, '$1$2.$3-$4');	
		else if( value.length < 11)
			return value.padStart(11, '0');
		else
			return value.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
	}
}