import { HttpClient, HttpParams, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
//import { Storage } from '@ionic/storage';
import { AppStorageProvider } from './app-storage-provider';
import { retry, timeout } from 'rxjs/operators';
import * as Env from './env';
import { AutenticacaoService } from '../core/autenticacao.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import {any} from "codelyzer/util/function";

export class ServicesProvider {
    public host = Env.ADDRESS;

    public header = { 'Content-Type': 'application/json; charset=UTF-8', 'Cache-Control': 'no-cache'  };

    public timeoutMillis = 2 * 60 * 6000;
    public retryCount = 0;
    private token = '';
    private uid = '';
    public info

    constructor(
        private http: HttpClient,
        public auth: AutenticacaoService,
        public storage: AppStorageProvider,
        private toastr: ToastrService,
    ) {
        this.token = localStorage.getItem("id_token");
        this.info = JSON.parse( localStorage.getItem( 'id_token_claims_obj' ) )

    }

    async getHeaders() {
        const user = await this.storage.getUser();
        if (user && user.token) {
        }
        return this.header;
    }

    private dbNewDate() {
        return new Date().toISOString().slice(0, 19).replace('T', ' ')
    }

    public charformat(s: string) {
        if (s && typeof s === 'string') {
            return s.replace(/\D/g, '');
        } else {
            return s;
        }
    }

    async request(path, type: string = 'get', params: any = {}, notId: boolean = false): Promise<any> {
        const headers = await this.getHeaders();
        if ( !notId ) {
            let p = await this.storage.getUser() || {};
                    params.uuid = p.uuid.toString();
        }
        return this.httpRequest(this.host + path, headers, params, type);
    }

    async requestIE(path, type: string = 'get', params: any = {}): Promise<any> {
        const headers = await this.getHeaders();
        return this.httpRequest(this.host + path, headers, params, type);
    }

    /*Executar Script Post*/
    async executarScriptPost(path, args): Promise<any> {
        const headers = await this.getHeaders();
        let params = args;
        path = path + '&token=' + this.token;
        this.httpRequest(this.host + path, headers, params, 'post');
        return true;
    }

   /*  async versaoJenkins(){
        return new Promise(async (resolve, reject) => {
            this.http.get('./versaojenkins.txt').subscribe(data => {
                let txt: any = data;
                resolve(txt.text());
            })
        });
    }

    async changeLog(){
        return new Promise(async (resolve, reject) => {
            this.http.get('./changelog.md').subscribe(data => {
                let txt: any = data;
                resolve(txt.text());
            })
        });
    } */

    async requestSSC(rota): Promise<any> {
       
       //Produção  
       let path = 'https://apigateway.prodemge.gov.br/ssc-gord/retornaPapeisPorRecurso';
       let headers = { 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': 'Bearer d5d54575-00bd-3606-a740-9f3b1dd2fe00' }; 

      // Homologação Novo
       // let path =' http://homologacao.apigateway.prodemge.gov.br/ssc-gord/retornaPapeisPorRecurso'
      //  let headers = { 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': 'Bearer 9bc3adf8-3227-3859-b1ce-e52e6cf6c9be' };    

      //Homologação Antigo        
      /*let path = 'http://homologacao.apigateway.prodemge.gov.br/ssc/recurso/retornaPapeisPorRecurso'; // Pega os papeis em homologação
      let headers = { 'Content-Type': 'application/json; charset=UTF-8', 'Authorization': 'Bearer 079e336a-d265-331a-94c4-c2df082d4016' }; // Autentica os papeis em homologação*/

        let params = {
            "codigoRecurso": rota,
            "contextoApp": "gord"
        }
        
        return new Promise(async (resolve, reject) => {
            let request = this.http.post(path, params, { headers });
            request.pipe(timeout(this.timeoutMillis), retry(this.retryCount)).subscribe(
                (response: any) => { resolve(response); },
                (err: any) => { reject(err); }
            );
        });
        // this.httpRequest(this.host + path, headers, params, 'post');
    }

    upload(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        const path = "/empresa-2pda/importar";
        formData.append('arquivo', file);
        formData.append("token", this.token);

        const req = new HttpRequest('POST', this.host + path, formData, {
            reportProgress: true,
            responseType: 'json'
        });

        return this.http.request(req);
    }


    public async requestRecurso(dados): Promise<any> {

        const headers = await this.getHeaders();
       //const url = "https://homologacao.ssc.prodemge.gov.br/ssc-idp-frontend/rest/oidc/userinfo?token=" + dados.access_token;
        const url = "https://ssc.prodemge.gov.br/ssc-idp-frontend/rest/oidc/userinfo?token=" + dados.access_token;
        let params = null;

        return new Promise(async (resolve, reject) => {
            // let request = this.http.get(url, { headers, params });
            let request = this.http.post(url, params, { headers });
            request.pipe(timeout(this.timeoutMillis), retry(this.retryCount)).subscribe(
                (response: any) => { resolve(response); },
                (err: any) => { reject(err); }
            );
        });

    }

    private normalizeFields(obj: any): any {
        if (obj) {
            if (obj.cpf) {
                obj.cpf = this.charformat(obj.cpf);
            }
            if (obj.cnpj) {
                obj.cnpj = this.charformat(obj.cnpj);
            }
            if (obj.cpf_cnpj) {
                obj.cpf_cnpj = this.charformat(obj.cpf_cnpj);
            }
            if (obj.cpf_vinculo) {
                obj.cpf_vinculo = this.charformat(obj.cpf_vinculo);
            }
           
        }

        return obj;
    }

    private httpRequest(url, headers, params, type): Promise<any> {
        this.token = localStorage.getItem("id_token");
        params.token = this.token;
        return new Promise(async (resolve, reject) => {
            let request;
            switch (type) {
                case 'get':
                    request = this.http.get(url, { headers, params });
                    break;
                case 'post':
                    request = this.http.post(url, params, { headers });

                    break;
                case 'delete':
                    request = this.http.delete(url, { headers, params });
                    break;
                default:
                    break;
            }
            // if (type === 'get') {
            //     request = this.http.get(url, { headers, params });
            // } else if (type === 'post') {
            //     request = this.http.post(url, params, { headers });
            // } else if (type === 'delete') {
            //     request = this.http.delete(url, { headers, params });
            // }

            request.pipe(timeout(this.timeoutMillis), retry(this.retryCount)).subscribe(
                (response: any) => {
                    if (response && response.meta && response.meta.codigo && response.meta.codigo == 401)
                        this.auth.logout();
                    resolve(response);
                },
                (err: any) => { reject(err); }
            );
        });
    }

    async mergePesquisaParams(obj, idFieldName) {
        let p = await this.storage.getSearch() || {};
        if (idFieldName)
            obj[idFieldName] = p["cpf_cnpj"];
        obj.uuid = p.uuid;
        return obj;
    }

    async mergeVinculoParams(obj) {
        let p = await this.storage.getSearch() || {};
        //obj.uuid = p.uuid;
        let cpf = this.charformat(obj.cpf_vinculo);
        obj.cpf_vinculo = (cpf.length == 10) ? '0' + cpf : cpf;
        let params = {
            "tipo_vinculo_id": obj.tipo_vinculo_id,
            "nome": obj.nome,
            "cpf_vinculo": obj.cpf_vinculo,
            "uuid": p.uuid,
        }
        return params;
    }

    /*Pesquisa*/
    async pesquisa(args): Promise<any> {
        const headers = await this.getHeaders();
        args = this.normalizeFields(args);
        let params = args;
        params.grau_pesquisa = 1;     
        params.perfil_usuario_ssc = this.info.name

        let data = await this.httpRequest(this.host + '/pesquisa/iniciar', headers, params, 'post');

         if (data.response) {
            this.storage.clearStorage();
            await this.storage.setUser(data.response);
            await this.storage.setSearch(args);
        } 
      

        return data;
    }

    async pesquisaIE(args): Promise<any> {
        const headers = await this.getHeaders();
        args = this.normalizeFields(args);
        let params = args;
        params.grau_pesquisa = 1;
        let data = await this.httpRequest(this.host + '/pesquisa/iniciar', headers, params, 'post');
         if (data.response) {
            this.storage.clearStorage();
            await this.storage.setUser(data.response);
            await this.storage.setSearch(args);
        } 
        return data;
    }

    async requestIntegracoes(path, type: string = 'get', params: any = {}): Promise<any> {
        const headers = await this.getHeaders();      
        return this.httpRequest(this.host + path, headers, params, type);
    }


    /*Pesquisa*/

 

    /*SIP*/

    async individuo(): Promise<any> {
        let pesquisa = await this.storage.getSearch() || { cpf_cnpj: '01523837608' };
        return this.pessoa();       
    }

    async pessoa(): Promise<any> {
        const headers = await this.getHeaders();
        let params: any = await this.mergePesquisaParams({}, "cpf_cnpj");
        params = this.normalizeFields(params);
        return this.httpRequest(
            this.host + '/pessoa/individuo',
            headers,
            params,
            'get'
        );
    }
    async ie(args): Promise<any> {
        const headers = await this.getHeaders();
        let params: any = await this.mergePesquisaParams({}, "numero_pta");
        params = this.normalizeFields(params);
        params.numero_pta='0100055273864'
        params.uuid='650422cd-202b-4bcc-b978-8b0fb86d90c9'
        return this.httpRequest(
            this.host + '/sef/obter-cda',
            headers,
            params,
            'get'
        );
    } 
  

    async empresa(): Promise<any> {
        const headers = await this.getHeaders();
        let params: any = await this.mergePesquisaParams({}, "cpf_cnpj");

        //MOCK
        params.cnpj = "16636540000104";
        params.uuid = "73168bc9-554f-4292-bd98-67d8d9f7da70";
        params = this.normalizeFields(params);
        return this.httpRequest(
            this.host + '/jucemg/empresa',
            headers,
            params,
            'get'
        );
    }

    async empresaCPF(): Promise<any> {
        const headers = await this.getHeaders();
        let params: any = await this.mergePesquisaParams({}, "cpf");

        params = this.normalizeFields(params);
        return this.httpRequest(
            this.host + '/jucemg/empresaporcpf',
            headers,
            params,
            'get'
        );
    }


    async listarVinculos(): Promise<any> {
        const headers = await this.getHeaders();
        let params: any = {}
        params = await this.mergePesquisaParams({}, false);
        //params = this.normalizeFields(params);
        return this.httpRequest(
            this.host + '/pessoa/vinculo',
            headers,
            params,
            'get'
        );
    }


    async historicoConsulta(params): Promise<any> {
        const headers = await this.getHeaders();
        params = await this.mergePesquisaParams(params, "cpf_cnpj");
        params = this.normalizeFields(params);
        return this.httpRequest(
            this.host + '/detran/historicoVeiculo',
            headers,
            params,
            'get'
        );
    }
}
