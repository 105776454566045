import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrMaskerModule } from 'br-mask';
import { IonicModule } from '@ionic/angular';
import { AutocompleteComponent } from '../components/autocomplete/autocomplete.component';
import { AutocompleteComponentModule } from '../components/autocomplete/autocomplete.module';

@NgModule({
  imports:      [IonicModule, BrMaskerModule, AutocompleteComponentModule],
  declarations: [],
  exports:      [CommonModule, FormsModule, ReactiveFormsModule, BrMaskerModule, IonicModule, AutocompleteComponentModule]
})
export class SharedModule {}
