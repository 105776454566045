import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { OverlayService } from 'src/app/service/overlay.service';
import { ServicesProvider } from 'src/app/service/services';
import { AppStorageProvider } from 'src/app/service/app-storage-provider';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-diagram',
  templateUrl: './modal-diagram.page.html',
  styleUrls: ['./modal-diagram.page.scss'],
})
export class ModalDiagramPage implements OnInit {
  public nodeData: any = [];
  public sistema: any = [];
  public data: any = {};

  constructor(
    public modalController: ModalController,
    public formBuilder: FormBuilder,
    private overlayService: OverlayService,
    private service: ServicesProvider,
    private toastr: ToastrService,
    private navparams: NavParams,
    public storage: AppStorageProvider,
    ) {

  }

  ngOnInit() {
    this.data = this.navparams.get('item')
    if(this.data.ativo && this.data.ativo == '0'){
      this.data.tipo_vinculo = 'SÓCIO - INATIVO'
    }
  }

  
  closeModal() {
    this.modalController.dismiss();
  }

}