import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timestampPipe'
})
export class TimestampPipe implements PipeTransform {

  transform(value: string, format: string =  "DD/MM/YYYY HH:mm:ss"): any {

    

    // if((typeof value == 'undefined') || (value == null) ||  (value.length <= 0)) {
    //   return value;
    // }
    /*else if(value.length == 10){
      //value = value.replace(/\//g, '');
    }*/
    console.log(value, format);
    return moment(value, format).valueOf();
  }

}
