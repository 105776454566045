import { ServicesProvider } from 'src/app/service/services';
import { Component, OnInit } from '@angular/core';
import { STORAGE_TYPE } from 'src/app/shared/model/local-storage-types.enum';
import { AppStorageProvider } from 'src/app/service/app-storage-provider';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router } from '@angular/router';
import { AutenticacaoService } from 'src/app/core/autenticacao.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public retorno_versao: any = [];
  public numero_versao: any = null;
  public user: any =[];
  constructor(
    public service: ServicesProvider,
    public storage: AppStorageProvider,
    private idleService: BnNgIdleService,
    private router: Router,
    private auth: AutenticacaoService,


  ) { }

  ngOnInit() {
    this.versaoSistema()

    this.numero_versao = this.storage.get(STORAGE_TYPE.STORAGE_VERSAO)

    this.user = JSON.parse( localStorage.getItem( 'id_token_claims_obj' ) )
  }

  versaoSistema() {
    this.service.request( '/sistemas/ultima-versao-frontend', 'get', {}, true )
    .then( rs => {
      
      this.retorno_versao = rs.response.versao

      this.storage.set( STORAGE_TYPE.STORAGE_VERSAO, this.retorno_versao )

      if(this.numero_versao !== rs.response.versao) {
        this.numero_versao =  this.retorno_versao
      }

    } )
    .catch( err => {
      console.log( err )
    } )
  }
  
}
