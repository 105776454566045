import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AppStorageProvider } from 'src/app/service/app-storage-provider';
import { PersonPipe } from 'src/app/pipe/person.pipe'
import { OverlayService } from 'src/app/service/overlay.service';
import { ServicesProvider } from 'src/app/service/services';
import { Validators, FormBuilder, FormGroup, FormControl} from '@angular/forms';
import { STORAGE_TYPE } from 'src/app/shared/model/local-storage-types.enum';



@Component({
  selector: 'app-abrir-projeto',
  templateUrl: './abrir-projeto.component.html',
  styleUrls: ['./abrir-projeto.component.scss'], 
  //providers: [PersonPipe]
})
export class AbrirProjetoComponent implements OnInit {
  public myForm: FormGroup;
  public data_user: any = [];
  @Input() cpf_cnpj: string = ""

  constructor(

    private router: Router, 
    private storage: AppStorageProvider,
    private service: ServicesProvider,
    public overlayService: OverlayService,
    public formBuilder: FormBuilder,

    ) { 

      this.myForm = this.formBuilder.group({
            
        cpf_cnpj: [ "",[Validators.required]], // Validators.minLength(14), Validators.maxLength(18)
        rg: [''], //,  [Validators.required, Validators.minLength(8)]],
        // teste: new FormControl(this.cpfcnpj(), Validators.required),
        numero_pta: ['',[Validators.required]],
        numero_processo: ['',[Validators.required]],
        outro: ['',[Validators.required]],
        origem_processo_outro: [''],
        sistemas: this.formBuilder.group({
            all: true,
            jucemg: true,
            detran:  true,
            sidagro: true,
            cri:  true,
            sip:  true
        }),
    });

  }

  ngOnInit() {
    this.data_user = this.storage.get(STORAGE_TYPE.STORAGE_AUTUADOS)
    //this.id()
    

  }

  id(){
    this.storage.getUser().then(async user =>{ 
      if(user == null){
        this.myForm.value.cpf_cnpj = this.storage.get(STORAGE_TYPE.STORAGE_AUTUADOS)     
        const loading = await this.overlayService.loading();
        console.log(this.myForm.value)
        this.service.pesquisa(this.myForm.value)
        .then(result => {
            if (result.response.uuid) {
                this.router.navigate([], {
                    state: {
                        pesquisa: result,
                        data: this.myForm.value,
                    },
                    queryParams: {},
                    // skipLocationChange: true
                });
              }
        })
        .catch(err =>
            console.log("Erro ao conectar serviço", err)
        
        )
      .finally(() => loading.dismiss());
   
      }
    });
  }

  openLink(data){
   /*   let navigationExtras: NavigationExtras = {
       queryParams: { cpf_cnpj: data},

     };
      this.router.navigate(['home'], navigationExtras);
      let url=  this.router.navigate(['home'], navigationExtras);

      window.open(url.toString()+navigationExtras,'_blank') */
    let url = '/#/home';

    this.storage.getUser().then(async user =>{ 
      if(user != null){
        console.log("User:",user)
        this.storage.getSearch().then(obj =>{ 
          console.log("Obj:",obj);
          obj.uuid_pesq_origem_vinculo = user.uuid;
          obj.cpf_cnpj = new PersonPipe().transform(data.replace(/\D/g, ''));
          delete obj.sistemas;
          let str = Object
                .keys(obj)
                .map(k =>{ if( k != 'token') return k + '=' + obj[k]})
                .join('&');

          console.log(url+'?'+str);
          this.router.navigate([]).then(result => { 
            window.open( url+'?'+str, '_blank'); 
              console.log(result)
            }
          );
        });
      }if(user == null){
        //this.myForm.value.cpf_cnpj='12454720620'
       
      this.storage.getSearch().then(obj =>{ 
        console.log("Obj:",obj);
        obj.uuid_pesq_origem_vinculo = user.uuid;
        obj.cpf_cnpj = new PersonPipe().transform(data.replace(/\D/g, ''));
        delete obj.sistemas;
        let str = Object
              .keys(obj)
              .map(k =>{ if( k != 'token') return k + '=' + obj[k]})
              .join('&');

        console.log(url+'?'+str);
        this.router.navigate([]).then(result => { 
          window.open( url+'?'+str, '_blank'); 
            console.log(result)
          }
        );
      });
      }
      
    }); 

  }
}
