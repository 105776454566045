import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefone'
})
export class TelefonePipe implements PipeTransform {

  transform(value: any): any {
		if (value === '' || value == null || value === undefined)
			return value;
		else if( value.length <= 2)
			return value.replace(/^(\d{2})(\d)/g,"($1) $2");
		else if( value.length >2 )
			return value.replace(/(\d)(\d{4})$/,"$1-$2");

	}

}
