import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currentBR'
})
export class currentBrPipe implements PipeTransform {
  transform(value: any): any {
    if (value === '' || value == null || value === undefined) {
			return value;
		}
   // return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    return Number(value).toLocaleString('pt-BR', {minimumFractionDigits: 2})

    //return value.replace(/,/g, ".").setCharAt(value,value.length-3,',');
  }

  // setCharAt(str,index,chr) {
  //   if(index > str.length-1) return str;
  //   return str.substr(0,index) + chr + str.substr(index+1);
  // }
}
